import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const RightSidebar = props => (
  <Layout>
    <Helmet>
      <title>Adresse - Palette Sartoise</title>
      <meta name="description" content="adresse de la palette sartoise à sart-bernard" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Adresse</h2>
          <p>
            La Palette Sartoise se situe dans le village de Sart-Bernard(Assesse) en province de Namur.
          </p>
        </header>

        <div className="row gtr-150">
          <div className="col-8 col-12-medium">
            <section id="content">
              <h3>Carte</h3>
              <p>
                <iframe width="100%" height="450" frameborder="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10170.95139864715!2d4.950558!3d50.408723!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDI0JzE4LjkiTiA0wrA1Nic1NS45IkU!5e0!3m2!1sfr!2sus!4v1534152952912">
                </iframe>
                </p>
              <h3>Nous rejoindre</h3>
              <p>
                En venant de la N4, entrer dans le village de <b>Sart-Bernard</b> par la rue "Saint-Denys". <br/>
                Passer sous le pont du chemin de fer et devant l'église.<br/>
                Tourner à droite.<br/>
                Vous êtes arrivé! <br/>
              </p>
            </section>
          </div>
          <div className="col-4 col-12-medium">
            <section id="sidebar">
              <section>
                <h3>Palette Sartoise – N188</h3>
                <p>
                  Complexe sportif<br/>
                  Rue Morimont<br/>
                  5330 Sart-Bernard<br/>
                </p>
                <footer>
                  <ul className="actions">
                    <li>
                      <a href="https://www.google.com/maps/dir//Palette+Sartoise+-+N188,+Rue+Morimont+3,+5330+Assesse/@50.4054411,4.9494762,167m/data=!3m1!1e3!4m9!4m8!1m0!1m5!1m1!1s0x47c1bd4daf14dcc5:0x22ee715499ab717f!2m2!1d4.9498068!2d50.4055781!3e0?hl=fr-FR" className="button" target="_blank">
                        Itinéraire
                      </a>
                    </li>
                  </ul>
                </footer>
              </section>            
            </section>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default RightSidebar
